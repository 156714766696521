import './image-slider.scss';
import Swiper from 'swiper/bundle';
import Follower from '../../atoms/sliderfollow';
import inViewport from '../../utilities/js/inViewport';

class ImageSlider {
    constructor($element) {
        this.$imageSliderRoot = $element;
        this.$imageSlider = this.$imageSliderRoot.querySelector('.swiper');
        this.$slides = this.$imageSliderRoot.querySelectorAll('.swiper-slide');
        this.slider = [];

        this.$isReducedMotion = window.matchMedia(
            '(prefers-reduced-motion: reduce)'
        ).matches;
    }

    initialize() {
        if (this.$slides.length > 1) {
            this.initSlider();
            this.setEvents();
        }

        inViewport(
            this.$imageSliderRoot,
            (isVisible, target) => {
                if (isVisible) {
                    target.classList.toggle('animation-slide-in', true);
                } else {
                    target.classList.toggle('animation-slide-in', false);
                }
            },
            {}
        );
    }

    initSlider() {
        const sliderOffset = 200;

        this.slider = new Swiper(this.$imageSlider, {
            loop: false,
            autoplay: this.$isReducedMotion
                ? false
                : {
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true
                  },
            centeredSlides: false,
            slidesPerView: 'auto',
            initAttr: 'data-image-slider',
            responsive: false,
            slidesOffsetBefore: sliderOffset,
            slidesOffsetAfter: sliderOffset,
            spaceBetween: 16,
            breakpoints: {
                0: {
                    centeredSlides: true,
                    slidesPerView: 1
                },
                600: {
                    slidesPerView: 2.2
                },
                768: {
                    slidesPerView: 'auto'
                }
            }
        });
    }

    setEvents() {
        this.$follower = new Follower(this.$imageSlider, (direction) => {
            if (direction === 'left') {
                this.slider.slidePrev();
            } else {
                this.slider.slideNext();
            }
        });
    }
}

export { ImageSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $imageSliders = $context.querySelectorAll(
            '[data-image-slider="root"]'
        );
        if ($imageSliders.length > 0) {
            for (let i = 0; i < $imageSliders.length; i++) {
                const $imageSlider = new ImageSlider($imageSliders[i]);
                $imageSlider.initialize();
            }
        }
    }
});
